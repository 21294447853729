import '../assets/index.css';
import React from 'react';
import { Container, Navbar, Nav, Button, Row, Col } from 'react-bootstrap'


import logo from '../assets/images/logo.png';
import video from '../assets/images/videodemo.mp4';


function Home() {
    return (
        <Container fluid>
            <Navbar>
                <nav className="navbar">
                    <div className="navbar-logo pb-5">
                        <a href="https://techfactors.com" target="_blank" rel="noopener noreferrer">
                            <img src={logo} alt="logo" className="img-icon-fluid" />
                        </a>
                        <p className='descrip-charts pb-5'>Interactive Video</p>
                    </div>
                </nav>

            </Navbar>

            <div className='center-div'>
                <div className="video-card">
                    <div className="video-container">
                        <video controls className="video-player">
                            <source src={video} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Home;